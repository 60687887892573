import { Storage } from '@aws-amplify/storage';

export const getAWSStorageAsset = async (file) => {
    if (typeof file !== 'string' || file.trim() === '') {
        console.error('Invalid file name.');
        return "";
    }

    try {
        const url = await Storage.get(`Thumbnails/${file}`, { download: false });
        return url || "";
    } catch (error) {
        console.error(`Error retrieving file from AWS Storage: ${error.message}`);
        return ""; // You can return a default image URL or an empty string as a fallback
    }
};